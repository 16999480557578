<template>
	<base-layout v-on:refresh="refresh($event)">
		<div
			style="height: 85vh; overflow: auto; padding-bottom: 10px"
			class="scrollableDiv"
		>
			<ion-list lines="none">
				<ion-item> </ion-item>
			</ion-list>
		</div>
	</base-layout>
</template>

<script>
	//import moment from "moment";

	import { IonList, IonItem } from "@ionic/vue";
	import { add, settings } from "ionicons/icons";

	export default {
		// eslint-disable-next-line vue/multi-word-component-names
		name: "Dashboard",

		components: {
			IonList,
			IonItem,
		},
		data() {
			return {};
		},
		computed: {},
		methods: {},
		setup() {
			return {
				add,
				settings,
			};
		},

		mounted() {},
	};

	/*
	  <scores-widget :cirlce1="circle1" :circle2="circle2" :circle3="circle3"/>
	       */
</script>

<style scoped></style>
